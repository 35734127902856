import { useState } from "react";
import { Link } from "react-router-dom";

const LinkL1xWallet = (props) => {
  const [l1xWalletAddress, setL1XWalletAddress] = useState("");

  console.log("saleType", localStorage.getItem("selectedTab"));
  return (
    <div className="container">
      <div className="claim-card ">
        <h1>Link Your Wallet</h1>

        <div className="claim-form">
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              L1X Wallet Address
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter L1X Wallet Address"
              onChange={(event) => setL1XWalletAddress(event.target.value)}
            />
          </div>

          {props.loader ?
            <button
              // onClick={() => props.claimToken(l1xWalletAddress)}
              disabled
              className="btn btn-gradient"
            >
              processing ...
            </button> : <button
              onClick={() => props.claimToken(l1xWalletAddress)}
              className="btn btn-gradient"
            >
              Claim
            </button>}
          <h6 className="generate-wallet-note">
            <Link to="https://blog.l1x.foundation/p/10c7fb66-3675-4930-a23c-15551ef9d0a1/" target="_blank">See how to generate an L1X Wallet Address</Link>
          </h6>
        </div>
      </div>
    </div>
  );
};

export default LinkL1xWallet;
