import React, { useEffect, useRef, useState } from "react";
import L1xDexLogoHeadLogo from "../assets/images/l1x-dex-logo.svg";
import RemindIcon from "../assets/images/remind.svg";
import { getAllNetworks } from "./../utils/networks/index";

const Header = (props) => {
  const [networkList, setNetworkList] = useState([]);




  useEffect(() => {
    listOfNetworks();
  }, []);

  const listOfNetworks = () => {
    const list = getAllNetworks();
    setNetworkList(list);
  };

  return (
    <header ref={props.headerRef} >
      <nav className="navbar navbar-expand-lg navbar-dark">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={L1xDexLogoHeadLogo} alt="Logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse"  id="navbarNav" style={{marginLeft:-164}}>
            <ul className="navbar-nav mx-auto nav__item__head">
              {/* <li className="nav-item">
                <a className="nav-link" href="/swap">
                  Swap
                </a>
              </li> */}
              <li className="nav-item">
                <a className="nav-link" href="/claim">
                  Claim
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/claim">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="/pool">
                  Pool
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/lending">
                  Lending
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/p2p">
                  P2P
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/staking">
                  Staking
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/staking-platform">
                  L1X Stake
                </a>
              </li> */}



              {/* <li className="nav-item">
                <a className="nav-link nav__item__link__head" href="#"> */}
              {/* &#9679; &#9679; &#9679; */}
              {/* </a>
              </li> */}
            </ul>

            {/* {!props?.isConnected && (
              <>
                <button
                  onClick={() => props.handleConnect()}
                  className="btn btn__head__connect__w"
                >
                  Connect Wallet
                </button>
                <div className="custom-select-wrapper">
                  <select className="custom-select select__tab__chart abitrum__select__head">
                    <option disabled value={""} selected>
                      Network
                    </option>
                    {networkList.map((network, index) => (
                      <option key={index} value={network.symbol}>
                        {network.name}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )} */}
            {/* <button className="btn btn__notification__head">
              <img src={RemindIcon} alt="Notification" />
            </button> */}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
