import { getData, postData, postRawJsonData } from "../Api/index";


async function servicePostDispute(data) {
  let _response = await postRawJsonData(
    `api/l1xdex/claim/dispute_ticket`,
    data
  );
  return _response;
}

// Check Private sale Claim Status 
async function serviceCheckClaimStatus(_walletAddress) {
  let _response = await getData(
    `api/l1xdex/claim/check_claim_status?walletAddress=${_walletAddress}`
  );
  return _response;
}

// Check Public sale Claim Status 
async function serviceCheckPublicSaleClaimStatus(_email) {
  let _response = await getData(
    `api/l1xdex/claim/check_public_sale_claim_status?email=${_email}`
  );
  return _response;
}

// Check Term Sheet Claim Status 
async function serviceCheckTermSheetClaimStatus(_email) {
  let _response = await getData(
    `api/l1xdex/claim/check_term_sheet_claim_status?email=${_email}`
  );
  return _response;
}

// Check Public Sale Emp Claim Status 
async function serviceCheckPublicSaleEmpClaimStatus(_walletAddress) {
  let _response = await getData(
    `api/l1xdex/claim/check_public_sale_emp_claim_status?walletAddress=${_walletAddress}`
  );
  return _response;
}

// Private Sale Claim Token 
async function serviceClaimToken(data) {
  let _response = await postRawJsonData(`api/l1xdex/claim/claim_tokens`, data);
  return _response;
}

// Public Sale Claim Token
async function servicePublicSaleClaimToken(data) {
  let _response = await postRawJsonData(`api/l1xdex/claim/public_sale_claim_tokens`, data);
  return _response;
}

// Term Sheet Claim Token
async function serviceTermSheetClaimToken(data) {
  let _response = await postRawJsonData(`api/l1xdex/claim/term_sheet_claim_tokens`, data);
  return _response;
}

// Public Sale Emp Investment Claim Token
async function servicePublicSaleEmpClaimToken(data) {
  let _response = await postRawJsonData(`api/l1xdex/claim/public_sale_emp_claim_tokens`, data);
  return _response;
}

async function servicePublicSaleClaimEmail(data) {
  let _response = await postRawJsonData(`api/l1xdex/claim/claim_process_send_otp`, data);
  return _response;
}

async function servicePublicSaleClaimOTPVerfication(data) {
  let _response = await getData(`api/l1xdex/claim/claim_process_verify_otp?email=${data.email}&otp=${data.otp}&type=${data.type}`);
  return _response;
}

// Check Seed Investor Claim Status 
async function serviceCheckSeedInvestorClaimStatus(_email) {
  let _response = await getData(
    `api/l1xdex/claim/check_seed_investor_claim_status?email=${_email}`
  );
  return _response;
}

// Seed Investor Claim Token
async function serviceSeedInvestorClaimToken(data) {
  let _response = await postRawJsonData(`api/l1xdex/claim/seed_investor_claim_tokens`, data);
  return _response;
}


export {
  serviceCheckClaimStatus,
  servicePostDispute,
  serviceClaimToken,
  serviceCheckPublicSaleClaimStatus,
  serviceCheckTermSheetClaimStatus,
  servicePublicSaleClaimToken,
  serviceTermSheetClaimToken,
  servicePublicSaleClaimEmail,
  servicePublicSaleClaimOTPVerfication,
  serviceCheckPublicSaleEmpClaimStatus,
  servicePublicSaleEmpClaimToken,
  serviceCheckSeedInvestorClaimStatus,
  serviceSeedInvestorClaimToken
};
