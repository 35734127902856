import { useState } from "react";
import { NumericFormat } from "react-number-format";

const TermSheetCoinAllocation = (props) => {
    console.log("props", props);
    return (
        <div className="container">
            <div className="claim-card ">
                <h1>Coin Allocation</h1>
                {/* <h4>Linked MetaMask Address</h4>
        <div className="copy-block">
          {props.walletAddress || "Address not found"}
        </div> */}
                <div className="claim-table-wrapper">
                    <ul>
                        <li>
                            <span>Term Sheet Investment:</span>
                            <abbr>
                                <NumericFormat
                                    disabled={true}
                                    thousandSeparator={true}
                                    className="number__styles"
                                    placeholder=""
                                    inputMode="numeric"
                                    suffix=" USD"
                                    value={props?.allocationData?.totalInvestment}
                                />
                            </abbr>
                        </li>
                        <li>
                            <span>Term Sheet Allocated:</span>
                            <abbr>
                                <NumericFormat
                                    disabled={true}
                                    className="number__styles"
                                    thousandSeparator={true}
                                    placeholder=""
                                    inputMode="numeric"
                                    value={props?.allocationData?.l1xAllocated || "0.00"}
                                />
                            </abbr>
                        </li>
                        <li>
                            <span>Total Allocation:</span>
                            <abbr>
                                <NumericFormat
                                    disabled={true}
                                    className="number__styles"
                                    thousandSeparator={true}
                                    placeholder=""
                                    inputMode="numeric"
                                    suffix="  L1X"
                                    value={props?.allocationData?.l1xAllocated}
                                />
                            </abbr>
                        </li>
                    </ul>
                </div>
                <ul className="action-wrapper">
                    <li>
                        <button
                            onClick={() => { props.handleType("public"); props.handlePage("disputeTicket") }}
                            className="btn btn-pink"
                        >
                            Dispute
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={() => props.handlePage("termSheetClaimNotice")}
                            className="btn btn-gradient"
                        >
                            Claim Coins
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default TermSheetCoinAllocation;
