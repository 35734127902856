import { findObjectWithSpecificValue } from "../helpers";
import networks from "./network";

const getAllNetworks = () => {
    const allNetworks = networks;
    return allNetworks;
}

const getNetworkByChainID = (_chainId) => {
    const allNetworks = networks;
    const network = findObjectWithSpecificValue(allNetworks, "chainId", _chainId);
    return network;
}

const getNetworkBySymbol = (_symbol) => {
    const allNetworks = networks;
    const network = findObjectWithSpecificValue(allNetworks, "symbol", _symbol);
    return network;
}

const getTokenBySymbol = async(_network, _tokenSymbol) => {
    const tokenList = await getTokenList(_network);
    const token = findObjectWithSpecificValue(tokenList, "tokenSymbol", _tokenSymbol);
    return token;
}

const getTokenByAddress = async(_network, _tokenAddress) => {
    const tokenList = await getTokenList(_network);
    const token = findObjectWithSpecificValue(tokenList, "tokenAddress", _tokenAddress);
    return token;
}

const getTokenList = async(_network) => {
    const allNetworks = networks;
    const network = findObjectWithSpecificValue(allNetworks, "symbol", _network);
    const tokenList = network.tokenList;

    return tokenList;
}

const getTokenAddress = (_network, _token) => {
    const allNetworks = networks;
    const network = findObjectWithSpecificValue(allNetworks, "symbol", _network);
    return network;
}


const getNetworkIcon = (_network) => {
    const allNetworks = networks;
    const network = findObjectWithSpecificValue(allNetworks, "symbol", _network);
    return network;
}


export {
    getAllNetworks,
    getNetworkByChainID,
    getNetworkBySymbol,
    getTokenBySymbol,
    getTokenList,
    getTokenAddress,
    getNetworkIcon,
    getTokenByAddress
}