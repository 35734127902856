import { useState } from "react";
import validator from 'validator';
import { servicePostDispute } from "../../services/claimService";
import Notify from "../notification";

const DisputeTicket = (props) => {

  const [errors, setErrors] = useState({})
  const [data, setData] = useState({
    email: '',
    supportTransaction: '',
    walletAddress: '',
    referralCode: '',
    problem: '',
    network: '',
    saleType:props.saleType
  })
  const [loading, setLoading] = useState(false);


  const handleDisputeForm = async (event) => {
    // event.de
    event.preventDefault()
    let tempData = { ...data }
    console.log("🚀 ~ file: disputeTicket.js:22 ~ handleDisputeForm ~ tempData:", tempData)
    let errors = {};
    if (validator.isEmpty(tempData.email)) {
      errors['email'] = "Please enter email address"
    } else if (!validator.isEmail(tempData.email)) {
      errors['email'] = "Please enter valid email address"
    }

    if (validator.isEmpty(tempData.walletAddress)) {
      errors['walletAddress'] = "Please enter wallet address"
    }

    if (validator.isEmpty(tempData.problem)) {
      errors['problem'] = "Please enter text"
    }

    console.log("🚀 ~ file: disputeTicket.js:35 ~ handleDisputeForm ~ errors:", errors)
    setErrors(errors)


    if (Object.keys(errors).length === 0) {
      setLoading(true);
      // servicePostDispute
      let _response = await servicePostDispute(data);
      console.log("🚀 ~ file: disputeTicket.js:43 ~ handleDisputeForm ~ _response:", _response.status)
      if (_response.status.toString() == "success") {
        // Notify(_response.message, 'success');
        setData({
          ...data,
          email: '',
          supportTransaction: '',
          walletAddress: '',
          referralCode: '',
          problem: '',
          network: ''
        })

        setLoading(false);

        props.handlePage("disputeThankYouMessage")
        return Notify(_response.message, "success");


      }
      else {
        setLoading(false);
        Notify(_response.message, 'error')

      }
    }
  }

  const handleForm = async (event) => {
    event.preventDefault()
    let tempData = { ...data }
    console.log("🚀 ~ file: disputeTicket.js:54 ~ handleForm ~ tempData:", tempData)
    tempData[event.target.name] = event.target.value;

    setData(tempData)

  }
  return (
    <div className="container">
      <div className="claim-card ">
        <h1>Dispute Ticket</h1>

        <form onSubmit={(event) => { handleDisputeForm(event) }} className="claim-form">
          <div class="form-group">
            <label for="exampleFormControlInput1" class="form-label">
              Email
            </label>
            <input value={data.email} onChange={(event) => handleForm(event)} name="email" type="email" class="form-control" placeholder="Email *" />
          </div>
          <div class="form-group">
            <label for="exampleFormControlInput1" class="form-label">
              Support Transaction
            </label>
            <input value={data.supportTransaction} onChange={(event) => handleForm(event)} name="supportTransaction" type="text" class="form-control" placeholder="TXN Hash" />
          </div>
          <div class="form-group">
            <label for="exampleFormControlInput1" class="form-label">
              Wallet Address
            </label>
            <input
              value={data.walletAddress}
              onChange={(event) => handleForm(event)}
              type="text"
              name="walletAddress"
              class="form-control"
              placeholder="0x13....6278*"
            />
          </div>
          <div class="form-group">
            <label for="exampleFormControlInput1" class="form-label">
              Referral Code
            </label>
            <input value={data.referralCode} onChange={(event) => handleForm(event)} name="referralCode" type="text" class="form-control" placeholder="6142..." />
          </div>

          <div class="form-group">
            <label class="form-label">Problem/Issue</label>
            <textarea
              value={data.problem}
              onChange={(event) => handleForm(event)}
              name="problem"
              class="form-control"
              placeholder="Text *"
              rows="3"
            ></textarea>
          </div>
          <ul className="action-wrapper">
            <li>
              <button onClick={() => props.handlePage('connectMetamask')} className="btn btn-pink">Cancel</button>
            </li>
            <li>
              {
                loading ? <button disabled className="btn btn-gradient">Sending</button> :
                  <button type="submit" className="btn btn-gradient">Send</button>
              }

            </li>
          </ul>
        </form>
      </div>
    </div>
  );
};

export default DisputeTicket;
