function setData(_key,_value){
    try
    {
        if(typeof _value == "object"){
            _value = JSON.stringify(_value);
        }
        window.localStorage.setItem(_key,_value);
        return true;
    }
    catch(e){
        console.error("Error Occured while storing data");
        return false;    
    }
}

function getData(_key){
    try
    {
        let _data = window.localStorage.getItem(_key);
        
        if(_data == null){
            return false;
        }
        
        return JSON.parse(_data);
    }
    catch(e){
        console.error("Error Occured while getting data");
        return false;    
    }
}

function removeData(_key){
    try
    {
        window.localStorage.removeItem(_key);
    }
    catch(e){
        return false;
    }
}

module.exports = {
    setData,
    getData,
    removeData
}