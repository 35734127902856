import { useState } from "react";
import { Link } from "react-router-dom";
import {
  serviceCheckPublicSaleClaimStatus,
  serviceClaimToken,
  servicePublicSaleClaimEmail,
} from "../../services/claimService";
import Notify from "../notification";
import validator from "validator";
import { ToastContainer } from "react-toastify";
import Config from "../../assets/config/";

const ConnectMetamask = (props) => {
  // usetstate for storing and retrieving wallet details

  const [publicKey] = useState("");
  const [selectedTab, setSelectedTab] = useState("public");

  localStorage.setItem("selectedTab", selectedTab);

  const resendOTP = async () => {
    let _emailResponse = await servicePublicSaleClaimEmail({
      email: props.email,
      type: 2
    });


    if (_emailResponse.status === "success") {
      Notify("OTP resend", "success");
      return;
    } else {
      Notify("Error while sending email", "error");
      return;
    }
  };

  //========================================= RESEND TERM SHEET OTP ==========================
  const resendTermSheetOTP = async () => {
    let _emailResponse = await servicePublicSaleClaimEmail({
      email: props.termSheetEmail,
      type: 3
    });


    if (_emailResponse.status === "success") {
      Notify("OTP resend", "success");
      return;
    } else {
      Notify("Error while sending email", "error");
      return;
    }
  };

  //========================================= RESEND SEED INVESTOR OTP ==========================
  const resendSeedInvestorOTP = async () => {
    let _emailResponse = await servicePublicSaleClaimEmail({
      email: props.seedInvestorEmail,
      type: 5
    });


    if (_emailResponse.status === "success") {
      Notify("OTP resend", "success");
      return;
    } else {
      Notify("Error while sending email", "error");
      return;
    }
  };

  return (
    <div className="container">
      <div className="text-center">
        <ul className="claim-tabs">
          <li className={selectedTab == "private" ? "active" : ""}>
            <Link
              onClick={() => {
                setSelectedTab("private");
              }}
            >
              Private Sale
            </Link>
          </li>
          <li className={selectedTab == "public" ? "active" : ""}>
            <Link
              onClick={() => {
                setSelectedTab("public");
              }}
            >
              Public Sale
            </Link>
          </li>
          <li className={selectedTab == "termSheet" ? "active" : ""}>
            <Link
              onClick={() => {
                setSelectedTab("termSheet");
              }}
            >
              Term Sheet
            </Link>
          </li>
          <li className={selectedTab == "publicSaleEmpInvestment" ? "active" : ""}>
            <Link
              onClick={() => {
                setSelectedTab("publicSaleEmpInvestment");
              }}
            >
              EMP Public Sale
            </Link>
          </li>
          <li className={selectedTab == "seedInvestor" ? "active" : ""}>
            <Link
              onClick={() => {
                setSelectedTab("seedInvestor");
              }}
            >
              Seed Investor
            </Link>
          </li>
        </ul>

      </div>

      {
        selectedTab === "private" ? (
          <div className="claim-card ">
            <h1>Connect MetaMask</h1>
            <p>{publicKey}</p>
            <p>{props.walletNotFound}</p>
            {
              Config.MAINTENANCE_MODE === true ?
                <>
                  <h1 style={{ "color": "orange" }}>The systems are under Upgrade and we will be back within 48 hours.</h1>:
                </>
                :
                <>
                  {props.loading ? (
                    <button disabled className="btn btn-gradient">
                      Connecting ...
                    </button>
                  ) : (
                    <button
                      onClick={() => props.checkClaim()}
                      className="btn btn-gradient"
                    >
                      Link
                    </button>
                  )}
                </>


            }



          </div>
        )
          :
          selectedTab === "public" ? (
            <div className="claim-card ">

              {props.showOTP == false ? (
                <>
                  <h1>Enter Email Address</h1>
                  <div className="claim-form">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlInput1" className="form-label">
                        Email Address
                      </label>
                      <input
                        disabled={props.showOTP}
                        name="email"
                        onChange={(event) => props.handleEmail(event.target.value)}
                        value={props.email}
                        type="email"
                        className="form-control "
                        placeholder="Email Address*"
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {props.showOTP ? (
                <div className="claim-form">
                  <div className="form-group">
                    <label for="exampleFormControlInput1" className="form-label">
                      Enter OTP
                    </label>
                    <input
                      name="otp"
                      onChange={(event) => props.handleOTP(event.target.value)}
                      value={props.otp}
                      type="text"
                      className="form-control "
                      placeholder=" Enter OTP*"
                    />
                    <div className="text-center">
                      <a
                        href="javascript:void(0)"
                        className="resent-otp"
                        onClick={resendOTP}
                      >
                        Resend OTP
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <button
                onClick={() => props.checkPublicSaleClaim()}
                className="btn btn-gradient "
              >
                Submit
                {props.loading ? <span className="btn-loading"></span> : ""}
              </button>
            </div>
          )
            :
            selectedTab === "termSheet" ? (
              <div className="claim-card ">

                {props.showTermshetOTP == false ? (
                  <>
                    <h1>Enter Email Address</h1>
                    <div className="claim-form">
                      <div className="form-group">
                        <label htmlFor="exampleFormControlInput1" className="form-label">
                          Email Address
                        </label>
                        <input
                          disabled={props.showTermshetOTP}
                          name="email"
                          onChange={(event) => props.handleTermSheetEmail(event.target.value)}
                          value={props.termSheetEmail}
                          type="email"
                          className="form-control "
                          placeholder="Email Address*"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}

                {props.showTermshetOTP ? (
                  <div className="claim-form">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlInput1" className="form-label">
                        Enter OTP
                      </label>
                      <input
                        name="termSheetOtp"
                        onChange={(event) => props.handleTermSheetOTP(event.target.value)}
                        value={props.termSheetOtp}
                        type="text"
                        className="form-control "
                        placeholder=" Enter OTP*"
                      />
                      <div className="text-center">
                        <a
                          href="javascript:void(0)"
                          className="resent-otp"
                          onClick={resendTermSheetOTP}
                        >
                          Resend OTP
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}


                <button
                  onClick={() => props.checkTermSheetClaim()}
                  className="btn btn-gradient "
                >
                  Submit
                  {props.loading ? <span className="btn-loading"></span> : ""}
                </button>
              </div>
            ) :
              selectedTab === "publicSaleEmpInvestment" ?
                (

                  <div className="claim-card ">
                    <h1>Connect MetaMask</h1>
                    <p>{publicKey}</p>
                    <p>{props.walletNotFound}</p>

                    {
                      Config.MAINTENANCE_MODE === true ?
                        <>
                          <h1 style={{ "color": "orange" }}>The systems are under Upgrade and we will be back within 48 hours.</h1>:
                        </>
                        :
                        <>
                          {props.loading ? (
                            <button disabled className="btn btn-gradient">
                              Connecting ...
                            </button>
                          ) : (
                            <button
                              onClick={() => props.checkPublicSaleEmpClaim()}
                              className="btn btn-gradient"
                            >
                              Link
                            </button>
                          )}
                        </>
                    }
                  </div>
                ) :
                //================================================ SEED INVESTORS =======================================
                (
                  <div className="claim-card ">

                    {props.showSeedInvestorOTP == false ? (
                      <>
                        <h1>Enter Email Address</h1>
                        <div className="claim-form">
                          <div className="form-group">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              Email Address
                            </label>
                            <input
                              disabled={props.showSeedInvestorOTP}
                              name="email"
                              onChange={(event) => props.handleSeedInvestorEmail(event.target.value)}
                              value={props.seedInvestorEmail}
                              type="email"
                              className="form-control "
                              placeholder="Email Address*"
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {props.showSeedInvestorOTP ? (
                      <div className="claim-form">
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1" className="form-label">
                            Enter OTP
                          </label>
                          <input
                            name="seedInvestorOTP"
                            onChange={(event) => props.handleSeedInvestorOTP(event.target.value)}
                            value={props.seedInvestorOTP}
                            type="text"
                            className="form-control "
                            placeholder=" Enter OTP*"
                          />
                          <div className="text-center">
                            <a
                              href="javascript:void(0)"
                              className="resent-otp"
                              onClick={resendSeedInvestorOTP}
                            >
                              Resend OTP
                            </a>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}


                    <button
                      onClick={() => props.checkSeedInvestorClaim()}
                      className="btn btn-gradient "
                    >
                      Submit
                      {props.loading ? <span className="btn-loading"></span> : ""}
                    </button>
                  </div>
                )
      }
      <ToastContainer />
    </div>
  );
};

export default ConnectMetamask;
