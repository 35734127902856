const DisputeThankYouMessage = (props) => {

  return (
    <div className="container">
      <div className="claim-card ">
        <h1>Thank You.</h1>
        <p> We've received your request.</p>
        {
          <button onClick={() => props.handlePage('connectMetamask')} className="btn btn-pink">Back</button>
        }
      </div>
    </div>
  );
};

export default DisputeThankYouMessage;
