import React, { useState, useEffect } from "react";

import { DataTable } from "primereact/datatable";

import Notify from "../components/notification";
import DisputeThankYouMessage from "../components/claim/disputeThankYouMessage";

import { ToastContainer, toast } from "react-toastify";

import * as buffer from "buffer";
import { ethers } from "ethers";

import Storage from "../services/storageService";
import _Config from "../assets/config";
import PublicSaleCoinAllocation from "../components/claim/publicSale/publicSaleCoinAllocation";
import validator from "validator";
import PublicSaleClaimNotice from "../components/claim/publicSale/publicSaleClaimNotice";
import PublicSaleYourL1xCoins from "../components/claim/publicSale/publicYourL1xCoins";

import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { serviceGetWalletAddress } from "../services/earlyAccessStatusDataService";

import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";
import "react-toastify/dist/ReactToastify.css";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";

window.Buffer = buffer.Buffer;

const ViewEarlyAccessList = () => {
  const location = useLocation();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    wallet_address: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  useEffect(() => {
    // Get the current pathname
    const pathname = window.location.pathname;

    // Add a class to the body element
    document.body.classList.add("white-bg");

    // Remove the class when the component is unmounted
    return () => {
      document.body.classList.remove("white-bg");
    };
  }, []);
  const [statuses] = useState(["1", "0"]);

  const [loading, setLoading] = useState(true);
  const [walletInvestorsList, setWalletInvestorList] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const getSeverity = (status) => {
    switch (status) {
      case "0":
        return "danger";

      case "1":
        return "success";
    }
  };

  function renderValue(_value) {
    if (_value) {
      return "YES";
    }
    return "NO";
  }

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={renderValue(rowData.status)}
        severity={rowData.status == 1 ? "success" : "danger"}
      />
    );
  };

  function renderOptionValue(_value) {
    if (_value == "1") {
      return "Yes";
    } else if (_value == "0") {
      return "No";
    } else {
      return "Select One";
    }
  }

  const statusItemTemplate = (option) => {
    return (
      <Tag value={renderOptionValue(option)} severity={getSeverity(option)} />
    );
  };

  const renderHeader = () => {
    return (
      <React.Fragment>
        <div className="flex justify-content-center pe-0 ps-0 mb-3">
          <span className="p-input-icon-right">
            <i className="pi pi-search" />
          </span>
          <span className="p-input-icon-right">
            <i className="pi pi-search" />
            <InputText
              className="form-control"
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search Wallet Address"
            />
          </span>
        </div>
      </React.Fragment>
    );
  };

  const getWalletInvestorsList = async () => {
    try {
      setLoading(true);
      let _response = await serviceGetWalletAddress();
      if (_response.status === "success") {
        setWalletInvestorList(_response?.data?.list);
      }
      if (_response.status === "failure") {
      }
    } catch (e) {
      return toast.error(e.message);
    } finally {
      setLoading(false); // Stop loader
    }
  };

  const footer = `In total there are ${
    walletInvestorsList ? walletInvestorsList.length : 0
  } users.`;

  const renderWalletAddressCell = (rowData) => {
    return rowData.wallet_address ? rowData.wallet_address : "-";
  };

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        options={statuses}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={statusItemTemplate}
        placeholder={renderOptionValue(options.value)}
        className="p-column-filter"
        showClear
      />
    );
  };

  useEffect(() => {
    getWalletInvestorsList();
  }, []);

  return (
    <div>
      <div className="claim-middle-container accesslist">
        <div
          className="main_body_container"
          style={{ height: `calc(100vh - 280px)` }}
        >
          <div className="container-fluid">
            <div className="card">
              <div className="waitlist_container p-4 ">
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <h1 style={{ fontSize: "20px" }}>
                      <b>Private Sale</b>
                    </h1>
                  </div>
                  <div style={{ align: "right" }}>
                    <a
                      href="https://claim.l1xapp.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="btn btn-dark">L1x Claim Tokens</button>
                    </a>
                  </div>
                </div>

                <div className="">
                  {/* {loading ? (
                    <div className="loading-container justify-content-between">
                      <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                      <p>Loading...</p>
                    </div>
                  ) : ( */}
                  <DataTable
                    value={walletInvestorsList}
                    paginator
                    rows={25}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    tableStyle={{ minWidth: "50rem", padding: "20px" }}
                    header={renderHeader}
                    footer={footer}
                    filters={filters}
                    className="customTable "
                  >
                    <Column
                      body={renderWalletAddressCell}
                      field="wallet_address"
                      header="Wallet Address"
                      sortable
                      style={{ width: "25%" }}
                    ></Column>

                    {/* <Column
                      body={statusBodyTemplate}
                      field="status"
                      header="Early Access Status"
                      filterMenuStyle={{ width: "14rem" }}
                      style={{ minWidth: "12rem" }}
                      filter
                      filterElement={statusFilterTemplate}
                      showFilterMatchModes={false}
                    ></Column> */}
                  </DataTable>
                  {/* )} */}
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ViewEarlyAccessList;
