import _Config from "../assets/config";

const base_url = _Config.API_URL;
const ops_base_url = _Config.API_OPS_URL;

export function getData(url) {
  let authToken = localStorage.getItem("authToken");

  if (authToken) {
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "Bearer " + authToken,
    };
  }

  return fetch(`${base_url + url}`, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.responseCode
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: headers,
  })
    .then((response) => response.json())
    .then(async (data) => {
      if (data.responseCode == 401 && !url.includes("getAccessTokenDetails")) {
        localStorage.clear();
        window.location.href = "/";
      }
      return data;
    })
    .catch((error) => {
      return error;
    });
}

export function getData1(url) {
  return fetch(`${url}`, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
}

export function getOpsData1(url) {
  return fetch(`${ops_base_url + url}`, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
}

export function postData(url, data) {
  return fetch(`${base_url + url}`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },

    body: data,
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
}

export function postRawJsonData(url, data) {
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
  };
  let authToken = localStorage.getItem("authToken");

  if (authToken) {
    headers = {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "Bearer " + authToken,
    };
  }

  return fetch(`${base_url + url}`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    headers: headers,
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
}

export function postImage(url, data) {
  const formData = new FormData();
  formData.append("file", data.file);
  formData.append("destination", data.destination);
  return fetch(`${base_url + url}`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
}

export function postFormData(url, formData) {
  let authToken = localStorage.getItem("authToken");
  if (authToken) {
    var headers = {
      Authorization: "Bearer " + authToken,
    };
  }

  return fetch(`${base_url + url}`, {
    method: "POST",
    body: formData,
    headers: headers,
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
}
