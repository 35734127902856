import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let notification = "";

export default function Notify(notificationText, type) {
    // Sentry.init({
    //     // dsn: "https://f967f14631c246688faed62cf474c00c@o4504461740015616.ingest.sentry.io/4504604050980864%22",
    //     dsn: Config.sentry.dsn
    // });


    // Return false if recieved same error message multiple times
    if (notificationText === notification) {
        return false
    }

    notification = notificationText;

    // notification = notificationText;
    setTimeout(() => {
        notification = ''
    }, 5000);

    // if (type === 'winner') {
    //     return Sentry.captureMessage("NFT Winner Trigger: " + notificationText, "info");
    // }

    if (type === 'success') {

        return toast.success(notificationText, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });


    }

    if (type === 'error' || type === 'failure') {
        // Sentry.captureMessage(notificationText);
        return toast.error(notificationText, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }


}
