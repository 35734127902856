import { useState } from "react";
import { NumericFormat } from "react-number-format";

const TermSheetClaimNotice = (props) => {
    return (
        <div className="container">
            <div className="claim-card ">
                <h1>Claim Notice </h1>
                <p>
                    You agree this data shows the correct Coin allocation. Once confirmed
                    this cannot be undone.
                </p>
                <div className="claim-table-wrapper">
                    <ul>
                        <li>
                            <span>Term Sheet Investment:</span>
                            <abbr>
                                <NumericFormat
                                    disabled={true}
                                    thousandSeparator={true}
                                    className="number__styles"
                                    placeholder=""
                                    inputMode="numeric"
                                    suffix=" USD"
                                    value={props?.allocationData?.totalInvestment || "0.00"}
                                />
                            </abbr>
                            {/* {props?.allocationData?.privateSaleInvestment || "0.00"} USD</abbr> */}
                        </li>
                        <li>
                            <span>Term Sheet Allocated:</span>
                            <abbr>
                                <NumericFormat
                                    disabled={true}
                                    thousandSeparator={true}
                                    className="number__styles"
                                    placeholder=""
                                    inputMode="numeric"
                                    // suffix=" USD"
                                    value={props?.allocationData?.l1xAllocated || "0.00"}
                                />
                                {/* {props?.allocationData?.l1xAllocated || "0.00"} */}
                            </abbr>
                        </li>
                        {/* <li>
                            <span>Rate:</span>
                            <abbr>1 USD = {1 / props?.allocationData?.conversionRate} L1X</abbr>
                        </li> */}
                        {/* <li>
              <span>Referral Bonus:</span>
              <abbr>500 L1X</abbr>
            </li> */}
                        <li>
                            <span>Total Allocation:</span>
                            <abbr>
                                <NumericFormat
                                    disabled={true}
                                    thousandSeparator={true}
                                    className="number__styles"
                                    placeholder=""
                                    inputMode="numeric"
                                    suffix=" L1X"
                                    value={props?.allocationData?.l1xAllocated || "0.00"}
                                />

                                {/* {props?.allocationData?.totalInvestment} L1X */}
                            </abbr>
                        </li>
                    </ul>
                </div>
                <ul className="action-wrapper">
                    <li>
                        <button
                            onClick={() => { props.handleType("public"); props.handlePage("disputeTicket") }}
                            className="btn btn-pink"
                        >
                            Dispute
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={() => props.handlePage("linkL1xWallet")}
                            className="btn btn-gradient"
                        >
                            Claim Coins
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default TermSheetClaimNotice;
