import { useState } from "react";
import { NumericFormat } from "react-number-format";

const PublicSaleEmpCoinAllocation = (props) => {
  return (
    <div className="container">
      <div className="claim-card ">
        <h1>Coin Allocation</h1>
        <h4>Linked MetaMask Address</h4>
        <div className="copy-block">
          {props.walletAddress || "Address not found"}
        </div>
        <div className="claim-table-wrapper">
          <ul>
            <li>
              <span>Emp Sale:</span>
              <abbr>
                <NumericFormat
                  disabled={true}
                  thousandSeparator={true}
                  className="number__styles"
                  placeholder=""
                  inputMode="numeric"
                  suffix=" USD"
                  value={props.allocationData.empInvestment || "0.00"}
                />
              </abbr>
            </li>
            <li>
              <span>Emp Sale Allocated:</span>
              <abbr>

                <NumericFormat
                  disabled={true}
                  className="number__styles"
                  thousandSeparator={true}
                  placeholder=""
                  inputMode="numeric"
                  value={props.allocationData.l1xAllocated || "0.00"}
                />

              </abbr>
            </li>
            {
              props?.allocationData?.unclaimedAmount > 0 ?
                <li>
                  <span>Unclaimed Amount:</span>
                  <abbr>
                    <NumericFormat
                      disabled={true}
                      className="number__styles"
                      thousandSeparator={true}
                      placeholder=""
                      inputMode="numeric"
                      suffix=" USD"
                      value={props?.allocationData?.unclaimedAmount || "0.00"}
                    />
                  </abbr>
                </li> : ""
            }
            <li>
              <span>Rate:</span>
              <abbr>1 USD = {1 / props.allocationData.conversionRate} L1X</abbr>
            </li>
            <li>
              <span>Total Allocation:</span>
              <abbr>

                <NumericFormat
                  disabled={true}
                  className="number__styles"
                  thousandSeparator={true}
                  placeholder=""
                  inputMode="numeric"
                  suffix="  L1X"
                  value={props.allocationData.totalInvestment}
                />
              </abbr>
            </li>
          </ul>
        </div>
        <ul className="action-wrapper">
          <li>
            <button
              onClick={() => props.handlePage("publicSaleEmpDisputeTicket")}
              className="btn btn-pink"
            >
              Dispute
            </button>
          </li>
          <li>
            <button
              onClick={() => props.handlePage("publicSaleEmpClaimNotice")}
              className="btn btn-gradient"
            >
              Claim Coins
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PublicSaleEmpCoinAllocation;
