import { useState } from "react";
import { NumericFormat } from "react-number-format";

const TermSheetL1xCoins = (props) => {
    return (
        <div className="container">
            <div className="claim-card ">
                <h1>Your L1X Coins</h1>
                <div className="claim-table-wrapper">
                    <ul>
                        <li>
                            <span>Term Sheet Investment:</span>
                            <abbr>
                                <NumericFormat
                                    disabled={true}
                                    thousandSeparator={true}
                                    className="number__styles"
                                    placeholder=""
                                    inputMode="numeric"
                                    suffix=" USD"
                                    value={props.allocationData.totalInvestment || "0.00"}
                                />
                                {/* {props.allocationData.privateSaleInvestment || "0.00"} USD */}
                            </abbr>
                        </li>
                        <li>
                            <span>Term Sheet Allocated:</span>
                            <abbr>
                                {/* {props.allocationData.l1xAllocated || "0.00"} */}

                                <NumericFormat
                                    disabled={true}
                                    thousandSeparator={true}
                                    className="number__styles"
                                    placeholder=""
                                    inputMode="numeric"
                                    // suffix=" L1X"
                                    value={props.allocationData.l1xAllocated || "0.00"}
                                />
                            </abbr>
                        </li>
                        {/* <li>
                            <span>Rate:</span>
                            <abbr>1 USD = {1 / props.allocationData.conversionRate}L1X</abbr>
                        </li> */}
                        {/* <li>
              <span>Referral Bonus:</span>
              <abbr>500 L1X</abbr>
            </li> */}
                        <li>
                            <span>Total Allocation:</span>
                            <abbr>
                                <NumericFormat
                                    disabled={true}
                                    thousandSeparator={true}
                                    className="number__styles"
                                    placeholder=""
                                    inputMode="numeric"
                                    suffix=" L1X"
                                    value={props.allocationData.l1xAllocated || "0.00"}
                                />

                                {/* {props.allocationData.totalInvestment} L1X */}
                            </abbr>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default TermSheetL1xCoins;
