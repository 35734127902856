import { useState } from "react";

const PublicSaleInvestor = (props) => {
  return (
    <div className="container">
      <div className="claim-card">
        <h1>Connect MetaMask</h1>
        <p>Seed/Private sale </p>
        <p>
          Wallet address has no L1X Coin allocation available in the
          Seed/Pre-sale round. <br />
          Public sale claiming will be coming soon.
        </p>
        <button className="btn btn-gradient">Link</button>
      </div>
    </div>
  );
};

export default PublicSaleInvestor;
