const _Config = {
  base_url: "https://gateway-beta.l1xtestnet.com/api",
  // base_url: "http://192.168.68.111:25555/api",
  // base_url: "http://localhost:25555/api",

  API_URL: "https://alpha-gateway.l1x.foundation/",
  //API_URL: "https://staging-alpha-gateway.l1x.foundation/",
  //API_URL: "http://localhost:27698/",
  //API_URL: "http://localhost:27698/",

  API_OPS_URL: "https://ops-panel-api.l1x.foundation", //master endpoint

  BETA_PERIOD_INTREST: 5,
  BETA_REWARD_MONTH: 24,
  MAINNET_BETA_START_DATE: "2023-08-01",
  MAINNET_BETA_END_DATE: "2023-12-31",
  AUTHORIZED_MESSAGE_SEED: "I confirm this is my claiming address for L1X.",
  SIGNED_MESSAGE_KEY: "authorizedSign",
  MAINTENANCE_MODE: false,

  network: "testnet",
  mainnet: {
    solana_rpc:
      "https://divine-virulent-ensemble.solana-mainnet.quiknode.pro/157842d8d153ca4281b313fff22300044b4ef532/",
    explorer_url: "https://beta-explorer.l1xtestnet.com/transaction/",
  },
  testnet: {
    explorer_url: "http://localhost:3000/transaction",
  },
  floatingDecimal: 4,
  fiatDecimal: 4,
  solana_program_id: "MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr",
  crypto_compare_api_key:
    "ab057e77fa5faed065dae0cb8e5b86c1df62eba79dc530df6744b3d67be209a9",
  ipfs_gateway: "https://ipfs.io/",
  l1x_price: 0.5,
};

export default _Config;
