import ETHIcon from "./../../assets/images/token/ethereum.svg"
import USDCIcon from "./../../assets/images/token/stablecoin.svg"
import USDTIcon from "./../../assets/images/token/tether.png"
import Optimisim from "./../../assets/images/token/optimism.svg"

const isMainnet = true;
const networkType = isMainnet ? 'mainnet' : 'testnet';

const _config = {
    mainnet: {
        tokenAddress: {
            ethereum: {
                usdt: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
                usdc: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'
            },
            polygon: {
                usdt: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
                usdc: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174'
            },
            binance: {
                usdt: '0x55d398326f99059ff775485246999027b3197955',
                usdc: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d'
            },
            avalanche: {
                usdt: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
                usdc: '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e'
            },
            solana: {
                usdt: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
                usdc: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'
            },
            l1x: {
                usdt: '',
                usdc: ''
            },
            optimisim: {
                usdt: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
                usdc: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607'
            }
        },
        rpc: {
            ethereum: "https://1rpc.io/eth",
            polygon: "https://polygon-rpc.com",
            binance: "https://bscscan.com",
            avalanche: "https://api.avax.network/ext/bc/C/rpc",
            solana: "https://divine-virulent-ensemble.solana-mainnet.quiknode.pro/157842d8d153ca4281b313fff22300044b4ef532/",
            optimisim: "https://optimism.publicnode.com",
        },
        chainId: {
            ethereum: 1,
            polygon: 137,
            binance: 56,
            avalanche: 43144,
            solana: 3,
            optimisim: 10,
        },
        explorer: {
            ethereum: "https://etherscan.io",
            polygon: "https://explorer.matic.network",
            binance: "https://bscscan.com",
            avalanche: "https://snowtrace.io",
            solana: "https://explorer.solana.com/"
        },
        lpAddress: {
            ethereum: '0x4Fe891EB8E1012A6512873Ebd0F47D848E22b04E',
            polygon: "",
            binance: "",
            avalanche: '',
            solana: '',
            optimisim: "0x7Cb13c20b1c4aD08f6145c42a86d9DC019b0A26E",
            optimisim_goerli: "0x44436A43330122a61A4877E51bA54084D5BD0aC6",
            eth_goerli: "0xDa4140B906044aCFb1aF3b34C94A2803D90e96aA",
            matic_mumbai: "",
            bsc_testnet: "",
        }
    },
    testnet: {
        tokenAddress: {
            ethereum: {
                usdt: '0x6247ad86cc1cc62D04df63f3A9d159FD0aD93f34',
                usdc: '0x0808a2d2b6a9585159555b1A4345A28E1BcFF4B1'
            },

            polygon: {
                usdt: '0xe70796843F19f616103e5F797B1ef60BE0E5BB40',
                usdc: '0x914353957cD6c3382880bda956a97eE80551f395'
            },
            binance: {
                usdt: '0x9Da3bFcea83438F41Bb8382299DcE75F34C9b852',
                usdc: '0x57084a7e00FAAE5F205Fda223d479D6b8379cceA'
            },
            avalanche: {
                usdt: '0x9Da3bFcea83438F41Bb8382299DcE75F34C9b852',
                usdc: '0x57084a7e00FAAE5F205Fda223d479D6b8379cceA'
            },
            solana: {
                usdt: '',
                usdc: ''
            },
            l1x: {
                usdt: '',
                usdc: ''
            },
            optimisim_goerli: {
                L1XTest: "0x853f409f60D477b5e4ECdfF2f2094D4670AFA0A1"
            },
            eth_goerli: {
                L1XTest: "0x4603e703309Cd6C0b8bada1e724312242ef36ECb"
            }
        },
        rpc: {
            ethereum: 'https://ethereum-sepolia.blockpi.network/v1/rpc/public',
            polygon: "https://rpc-mumbai.maticvigil.com",
            binance: "https://data-seed-prebsc-1-s1.binance.org:8545/",
            avalanche: "https://api.avax-test.network/ext/bc/C/rpc",
            solana: "https://api.devnet.solana.com",
            optimisim_goerli: "https://endpoints.omniatech.io/v1/op/goerli/public",
            eth_goerli: "https://ethereum-goerli.publicnode.com"
        },
        chainId: {
            ethereum: 11155111,
            polygon: 80001,
            binance: 97,
            avalanche: 43113,
            solana: 3,
            optimisim_goerli: 420,
            eth_goerli: 5
        },
        explorer: {
            ethereum: '',
            polygon: "https://mumbai.polygonscan.com",
            binance: "https://testnet.bscscan.com",
            avalanche: '',
            solana: '',
            optimisim_goerli: "https://goerli-optimism.etherscan.io",
            eth_goerli: "https://goerli.etherscan.io"
        }
    }
}
const networks = [
    {
        "name": "Optimisim",
        "symbol": "OPM",
        "token": "OP",
        "icon": Optimisim,
        "chainId": _config[networkType].chainId?.['optimisim'],
        "rpc": _config[networkType].rpc?.['optimisim'],
        "type": "EVM",
        "explorer": _config[networkType].explorer?.['optimisim'],
        "isTestnet": false,
        "isSwapActive": true,
        "lpAddress": _config[networkType].lpAddress?.['optimisim'],
        "tokenList": [
            // {
            //     "tokenName": "L1XTest",
            //     "tokenSymbol": "L1X",
            //     "tokenDecimal": "18",
            //     "tokenAddress": "0x853f409f60D477b5e4ECdfF2f2094D4670AFA0A1",
            //     "icon": Optimisim,
            //     "isNative": false,
            //     "isSwapActive": true
            // }
            {
                "tokenName": "USDT",
                "tokenSymbol": "USDT",
                "tokenDecimal": "6",
                "tokenAddress": _config[networkType].tokenAddress.optimisim.usdt,
                "icon": USDTIcon,
                "isNative": false
            },
            {
                "tokenName": "USDC",
                "tokenSymbol": "USDC",
                "tokenDecimal": "6",
                "tokenAddress": _config[networkType].tokenAddress.optimisim.usdc,
                "icon": USDCIcon,
                "isNative": false
            }
        ]
    },
    {
        "name": "Ethereum",
        "symbol": "ETH",
        "token": "ETH",
        "icon": ETHIcon,
        "chainId": _config[networkType].chainId?.['ethereum'],
        "rpc": _config[networkType].rpc?.['ethereum'],
        "type": "EVM",
        "explorer": _config[networkType].explorer?.['ethereum'],
        "isTestnet": false,
        "isSwapActive": true,
        "lpAddress": _config[networkType].lpAddress?.['ethereum'],
        "tokenList": [
            // {
            //     "tokenName": "L1XTest",
            //     "tokenSymbol": "L1X",
            //     "tokenDecimal": "18",
            //     "tokenAddress": "0x4603e703309Cd6C0b8bada1e724312242ef36ECb",
            //     "icon": ETHIcon,
            //     "isNative": false,
            //     "isSwapActive": true
            // }

            {
                "tokenName": "USDT",
                "tokenSymbol": "USDT",
                "tokenDecimal": "6",
                "tokenAddress": _config[networkType].tokenAddress.ethereum.usdt,
                "icon": USDTIcon,
                "isNative": false
            },
            {
                "tokenName": "USDC",
                "tokenSymbol": "USDC",
                "tokenDecimal": "6",
                "tokenAddress": _config[networkType].tokenAddress.ethereum.usdc,
                "icon": USDCIcon,
                "isNative": false
            }
        ]
    }

    // {
    //     "name": "Ethereum",
    //     "symbol": "ETH",
    //     "token": "ETH",
    //     "icon": ETHIcon,
    //     "chainId": _config[networkType].chainId.ethereum,
    //     "rpc": _config[networkType].rpc.ethereum,
    //     "type": "EVM",
    //     "explorer": _config[networkType].explorer.ethereum,
    //     "isTestnet": false,
    //     "tokenList": [
    //         {
    //             "tokenName": "Ether",
    //             "tokenSymbol": "ETH",
    //             "tokenDecimal": "18",
    //             "tokenAddress": "",
    //             "icon": ETHIcon,
    //             "isNative": true
    //         },
    //         {
    //             "tokenName": "USDT",
    //             "tokenSymbol": "USDT",
    //             "tokenDecimal": "6",
    //             "tokenAddress": _config[networkType].tokenAddress.ethereum.usdt,
    //             "icon": USDTIcon,
    //             "isNative": false
    //         },
    //         {
    //             "tokenName": "USDC",
    //             "tokenSymbol": "USDC",
    //             "tokenDecimal": "6",
    //             "tokenAddress": _config[networkType].tokenAddress.ethereum.usdc,
    //             "icon": USDCIcon,
    //             "isNative": false
    //         }
    //     ]
    // },
    // {
    //     "name": "Polygon",
    //     "symbol": "MATIC",
    //     "token": "MATIC",
    //     "icon": MATICIcon,
    //     "chainId": _config[networkType].chainId.polygon,
    //     "rpc": _config[networkType].rpc.polygon,
    //     "type": "EVM",
    //     "explorer": _config[networkType].explorer.polygon,
    //     "isTestnet": false,
    //     "tokenList": [
    //         {
    //             "tokenName": "Polygon",
    //             "tokenSymbol": "MATIC",
    //             "tokenDecimal": "18",
    //             "tokenAddress": "",
    //             "icon": MATICIcon,
    //             "isNative": true
    //         },
    //         {
    //             "tokenName": "USDT",
    //             "tokenSymbol": "USDT",
    //             "tokenDecimal": "6",
    //             "tokenAddress": _config[networkType].tokenAddress.polygon.usdt,
    //             "icon": USDTIcon,
    //             "isNative": false
    //         },
    //         {
    //             "tokenName": "USDC",
    //             "tokenSymbol": "USDC",
    //             "tokenDecimal": "6",
    //             "tokenAddress": _config[networkType].tokenAddress.polygon.usdc,
    //             "icon": USDCIcon,
    //             "isNative": false
    //         }
    //     ]
    // },
    // {
    //     "name": "Binance Smart Chain",
    //     "symbol": "BSC",
    //     "token": "BNB",
    //     "icon": BSCIcon,
    //     "chainId": _config[networkType].chainId.binance,
    //     "rpc": _config[networkType].rpc.binance,
    //     "type": "EVM",
    //     "explorer": _config[networkType].explorer.binance,
    //     "isTestnet": false,
    //     "tokenList": [
    //         {
    //             "tokenName": "Binance",
    //             "tokenSymbol": "BNB",
    //             "tokenDecimal": "18",
    //             "tokenAddress": "",
    //             "icon": BSCIcon,
    //             "isNative": true
    //         },
    //         {
    //             "tokenName": "USDT",
    //             "tokenSymbol": "USDT",
    //             "tokenDecimal": "18",
    //             "tokenAddress": _config[networkType].tokenAddress.binance.usdt,
    //             "icon": USDTIcon,
    //             "isNative": false
    //         },
    //         {
    //             "tokenName": "USDC",
    //             "tokenSymbol": "USDC",
    //             "tokenDecimal": "18",
    //             "tokenAddress": _config[networkType].tokenAddress.binance.usdc,
    //             "icon": USDCIcon,
    //             "isNative": false
    //         }
    //     ]
    // },
    // {
    //     "name": "Avalanche C Chain",
    //     "symbol": "AVAX",
    //     "token": "AVAX",
    //     "icon": AVAXIcon,
    //     "chainId": _config[networkType].chainId.avalanche,
    //     "rpc": _config[networkType].rpc.avalanche,
    //     "type": "EVM",
    //     "explorer": "https://snowtrace.io",
    //     "isTestnet": false,
    //     "tokenList": [
    //         {
    //             "tokenName": "Avalanche",
    //             "tokenSymbol": "AVAX",
    //             "tokenDecimal": "18",
    //             "tokenAddress": "",
    //             "icon": AVAXIcon,
    //             "isNative": true
    //         },
    //         {
    //             "tokenName": "USDT",
    //             "tokenSymbol": "USDT",
    //             "tokenDecimal": "6",
    //             "tokenAddress": _config[networkType].tokenAddress.avalanche.usdt,
    //             "icon": USDTIcon,
    //             "isNative": false
    //         },
    //         {
    //             "tokenName": "USDC",
    //             "tokenSymbol": "USDC",
    //             "tokenDecimal": "6",
    //             "tokenAddress": _config[networkType].tokenAddress.avalanche.usdc,
    //             "icon": USDCIcon,
    //             "isNative": false
    //         }
    //     ]
    // },
    // {
    //     "name": "Solana",
    //     "symbol": "SOL",
    //     "token": "SOL",
    //     "icon": SOLIcon,
    //     "chainId": 3,
    //     "rpc": _config[networkType].rpc.solana,
    //     "type": "SOL",
    //     "explorer": _config[networkType].explorer.solana,
    //     "isTestnet": false,
    //     "tokenList": [
    //         {
    //             "tokenName": "Solana",
    //             "tokenSymbol": "SOL",
    //             "tokenDecimal": "9",
    //             "tokenAddress": "",
    //             "icon": SOLIcon,
    //             "isNative": true
    //         },
    //         {
    //             "tokenName": "USDT",
    //             "tokenSymbol": "USDT",
    //             "tokenDecimal": "6",
    //             "tokenAddress": _config[networkType].tokenAddress.solana.usdt,
    //             "icon": USDTIcon,
    //             "isNative": false
    //         },
    //         {
    //             "tokenName": "USDC",
    //             "tokenSymbol": "USDC",
    //             "tokenDecimal": "6",
    //             "tokenAddress": _config[networkType].tokenAddress.solana.usdc,
    //             "icon": USDCIcon,
    //             "isNative": false
    //         }
    //     ]
    // },
    // {
    //     "name": "Layer One X",
    //     "symbol": "L1X",
    //     "token": "L1X",
    //     "icon": L1xLogoIcon,
    //     "chainId": null,
    //     "rpc": "",
    //     "type": "L1X",
    //     "explorer": "",
    //     "isTestnet": false,
    //     "tokenList": [
    //         {
    //             "tokenName": "Layer One X",
    //             "tokenSymbol": "L1X",
    //             "tokenDecimal": "18",
    //             "tokenAddress": "",
    //             "icon": L1xLogoIcon,
    //             "isNative": true
    //         },
    // {
    //     "tokenName": "USDT",
    //     "tokenSymbol": "USDT",
    //     "tokenDecimal": "6",
    //     "tokenAddress": isMainnet ? "0xdAC17F958D2ee523a2206206994597C13D831ec7" : "0x6247ad86cc1cc62D04df63f3A9d159FD0aD93f34",
    //     "icon": USDTIcon,
    //     "isNative": false
    // },
    // {
    //     "tokenName": "USDC",
    //     "tokenSymbol": "USDC",
    //     "tokenDecimal": "6",
    //     "tokenAddress": isMainnet ? "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48" : "0x0808a2d2b6a9585159555b1A4345A28E1BcFF4B1",
    //     "icon": USDCIcon,
    //     "isNative": false
    // }
    // ]
    //     },
];

export default networks