import React from "react";
import L1xDexLogoFooter from "../assets/images/l1dex-logo-dark.svg";
import SocialIcon from "../assets/images/social-media-icons.svg";

const Footer = (props) => {
  return (
    <footer className="footer"  ref={props.footerRef} >
      <div className="container-fluid">
        {/* <div className="row">
          <div className="col-md-2">
            <div className="footer__icon__box">
              <img src={L1xDexLogoFooter} alt="logo" />
            </div>
          </div>
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-3">
                <div className="footer__url__box">
                  <h2>DEVELOPERS</h2>
                  <ul className="footer__url__box__ul">
                    <li>
                      <button className="btn btn__footer__link">
                        Build on Layer One X
                      </button>
                    </li>
                    <li>
                      <button className="btn btn__footer__link">
                        Grant Applications
                      </button>
                    </li>
                    <li>
                      <button className="btn btn__footer__link">
                        View Whitepaper{" "}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3">
                <div className="footer__url__box">
                  <h2>INVESTORS</h2>
                  <ul className="footer__url__box__ul">
                    <li>
                      <button className="btn btn__footer__link">
                        Register for Public Sale
                      </button>
                    </li>
                    <li>
                      <button className="btn btn__footer__link">
                        How it Works
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3">
                <div className="footer__url__box">
                  <h2>USE CASES</h2>
                  <ul className="footer__url__box__ul">
                    <li>
                      <button className="btn btn__footer__link">De-Fi</button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3">
                <div className="footer__url__box">
                  <h2>GENERAL</h2>
                  <ul className="footer__url__box__ul">
                    <li>
                      <button className="btn btn__footer__link">
                        Who We Are
                      </button>
                    </li>
                    <li>
                      <button className="btn btn__footer__link">
                        Join The Team
                      </button>
                    </li>
                    <li>
                      <button className="btn btn__footer__link">
                        Contact{" "}
                      </button>
                    </li>
                    <li>
                      <button className="btn btn__footer__link">
                        Terms of service{" "}
                      </button>
                    </li>
                    <li>
                      <button className="btn btn__footer__link">
                        Whitelist Terms & Conditions{" "}
                      </button>
                    </li>
                    <li>
                      <button className="btn btn__footer__link">
                        Cookie Policies{" "}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="copy__right__box__footer">
          <div className="row">
            <div className="col-md-2">
              <div className="footer__icon__box">
                <img src={L1xDexLogoFooter} alt="logo" />
              </div>
            </div>
            <div className="col-md-8 copy__box__right">
              <div className="copy__right__box">
                <div className="copy__txt__box">
                  <p>© 2023 Layer One X. All rights reserved.</p>
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
            {/* <div className="col-md-6">
              <div className="social__media__icon">
                <img src={SocialIcon} alt="social-icon" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
